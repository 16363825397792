import { CrossIcon, LoaderIcon } from '@gathercontent/icons';
import { NotificationBanner } from '@gathercontent/ui';
import { useFetcher } from '@remix-run/react';

import { useBannerCookies } from '~/data/banners/hooks/useBannerCookies';
import { useEnv } from '~/data/env/hooks/useEnv';

export function PlannedMaintenanceBanner() {
  const env = useEnv();
  const cookies = useBannerCookies();
  const fetcher = useFetcher();

  if (!env?.PLANNED_MAINTENANCE_TEXT || cookies?.plannedMaintenanceDismissed === 'dismissed') {
    return null;
  }

  return (
    <NotificationBanner level="warning">
      <fetcher.Form
        action="/api/dismiss-planned-maintenance"
        method="post"
        className="flex items-center justify-center"
      >
        <button title="dismiss planned maintenance banner" className="flex">
          <div className="flex-1">{env?.PLANNED_MAINTENANCE_TEXT}</div>

          <div>
            {fetcher.state === 'idle' && <CrossIcon iconSize="10" className="fill-neutral-20" />}

            {fetcher.state != 'idle' && <LoaderIcon iconSize="10" />}
          </div>
        </button>
      </fetcher.Form>
    </NotificationBanner>
  );
}
