import { useTypedRouteLoaderData as useRouteLoaderData } from 'remix-typedjson';
import type { loader } from '~/root';

import type { BannerCookies } from '~/data/banners/.types/bannerCookieTypes';

export function useBannerCookies(): BannerCookies | null {
  const root = useRouteLoaderData<typeof loader>('root');
  if (root?.bannerCookies?.data) {
    return root.bannerCookies.data;
  }
  return null;
}
